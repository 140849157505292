<template>
  <WeContainer
    header="ture"
    bread-crumb=""
    v-bind:actions="actions"
    v-if="ready"
  >
    <WeTable
      v-bind:index="false"
      v-bind:data="membertype.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-on:on-action="onAction"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "List",
  data() {
    return {
      ready: false,
      actions: [
        {
          text: "Yeni Üye Tipi",
          path: "member-types/new",
        },
      ],
      tableActions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [{ name: "name", th: "Üye Tipi", type: "string" }],
    };
  },
  methods: {
    ...mapActions("membertype", ["getList", "delete"]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      }
    },
    onEdit(row) {
      this.$router.replace("member-types/detail/" + row.id);
    },
    onDelete(row) {
      let message = `${row.name} üye tipini silmek istediğinize emin misiniz ?`;

      this.$swal({
        title: message,
        html: `Bu işlemi <b>geri alınamaz</b>.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Üye Tipi Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Üye Tipi Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    checkPermission(code) {
      return permission.check("memberType", code);
    },
  },
  computed: {
    ...mapState(["membertype"]),
  },
  mounted() {
    this.getList({
      onSuccess: (result) => {
        this.ready = true;
      },
    });
  },
};
</script>
